import { some } from "lodash-es"

const DIRECTION_ASC = "asc"
const DIRECTION_DESC = "desc"

const switchDirection = direction => {
  if (direction === DIRECTION_ASC) {
    return DIRECTION_DESC
  } else {
    return DIRECTION_ASC
  }
}

export const switchSorting = ({ direction, field, newField = undefined }) => {
  const sorting = { field }

  if (newField) {
    if (field === newField) {
      sorting["direction"] = switchDirection(direction)
    } else {
      sorting["field"] = newField
      sorting["direction"] = DIRECTION_ASC
    }
  } else {
    sorting["direction"] = switchDirection(direction)
  }

  return sorting
}

const sortingIconByDirection = direction => (direction === DIRECTION_ASC ? "sort-up" : "sort-down")

export const sortingIcon = (sortingData, field) => {
  return sortingData.field === field ? sortingIconByDirection(sortingData.direction) : "sort"
}

export const validateItems = items => !some(items, ({ isInvalid }) => isInvalid)
