<template lang="pug">
  .price-table
    table.container-fluid
      thead
        tr
          th.name.sortable(@click="handleSorting('name')")
            span {{ $t("company_system.rate_plan_settings.price_table.name") }}
            FaIcon.icon(:icon="sortingIcon('name')")
          th.time-division.sortable(@click="handleSorting('time_slot_name')")
            span {{ $t("company_system.rate_plan_settings.price_table.time_division") }}
            FaIcon.icon(:icon="sortingIcon('time_slot_name')")
          th.car-class.sortable(@click="handleSorting('car_class_names')")
            span {{ $t("company_system.rate_plan_settings.price_table.car_class") }}
            FaIcon.icon(:icon="sortingIcon('car_class_names')")
          th.last-updated.sortable(@click="handleSorting('updated_at')")
            span {{ $t("company_system.rate_plan_settings.price_table.last_updated") }}
            FaIcon.icon(:icon="sortingIcon('updated_at')")
          th.actions
      tbody
        template(v-for="item in items")
          tr(
            @click="$emit('select-row', item)"
          )
            td {{ item.name }}
            td {{ item.time_slot_name }}
            td {{ item.car_class_names }}
            td {{ item.updated_at }}
            td.remove-action
              AppIconButton.action-button(
                icon="trash-alt"
                @click.stop="$emit('remove-row', item)"
              )
</template>

<script>
  // mixins
  import withSorting from "@/mixins/withSorting"

  export default {
    components: {
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    mixins: [withSorting],

    props: {
      items: {
        type: Array,
        default: () => new Array()
      },

      sortingData: Object
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/matchings.sass"
  @import "@/assets/styles/mixins/common.sass"

  .price-table
    +listing-container(31px)
    margin-left: 10px
    margin-top: 30px
    overflow-x: hidden

    table
      +custom-table
      +matchings-table-header-sticky

      table-layout: fixed
      overflow-wrap: break-word
      overflow: auto

      thead
        th
          &.name
            width: 20%

          &.time-division
            width: 18%

          &.car-class
            width: 40%

          &.last-updated
            width: 18%

          &.actions
            width: 4%
      tbody
        tr
          cursor: pointer

          td
            &.remove-action
              min-width: 25px
              color: $default-purple
              text-align: center
              margin-top: 5px

              .action-button
                cursor: pointer
                +icon-button($default-purple)
</style>
