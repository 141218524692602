import { switchSorting, sortingIcon } from "@/helpers/matching"

/**
 * Creates mixin for using in sortable table
 * The component must contain sortingData object in props
 *
 * @return {object} Mixin object
 *
 * @example
 * import withSorting from "@/mixins/withSorting"
 *
 * // Your component
 * export default {
 *   ...
 *   mixins: [withSorting]
 *   ...
 *   props: {
 *     sortingData = {
 *       field: "name",
 *       direction: "asc"
 *     }
 *   }
 *   ...
 * }
 *
 *
 */
export default {
  methods: {
    handleSorting(newField) {
      this.$emit("sorting", switchSorting({ ...this.sortingData, newField }))
    },

    sortingIcon(field) {
      return sortingIcon(this.sortingData, field)
    }
  }
}
